#wrapper_body_components {
  display: flex;
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  width: 1000px;
  position: fixed;
  margin-top: 5px;
}

#page_config_submission, #page_config_details {
  border: solid 1px white;
   padding: 10px;
}

#page_config_submission {
    display: flex;
    flex-direction: column; /* Added to stack the elements vertically */
    align-items: flex-start; /* Added to align the elements to the left */
    width: 50%; /* Adjusted width to account for the left and right margins */
}



#page_input_title {
  margin-bottom: 10px;
}

#page_input_select{
  margin-left: 10px;
}

#wrapper_labels {
  display: flex;
  color: white;
}

#wrapper_labels p {
  margin: 0;
  font-size: 14px;
  margin-top: -4px;
  background-color: rgb(0, 111, 96);
  z-index: 99;
}

#label_Submission_Form {
  margin-left: 3% !important;
}

#label_Field_Details {
  margin-left: 170px !important;
}

#select_field {
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 10px;
}



#buttons_container {
    display: flex;
    gap: 10px;              /* Provides spacing between the buttons */
    justify-content: flex-end;
}

#buttons_container Button:first-child {
    margin-right: 15px;
}

#button_config_edit_save,
#button_config_edit_delete {
    margin-top: 10px;
    margin-left: 10px; /* Provides spacing above the buttons */

}

#input_field_name, #textarea_description, #textarea_prompt,#include_source,#client_specific {
  width: calc(100% - 20px); /* Adjusted width to account for the left and right margins */
  margin-left: 10px ; /* Added margin to separate the elements */
  box-sizing: border-box;
  margin-top: 10px; /* Provides spacing above the elements */
}

.include-source-wrapper {
    display: inline-flex;
    align-items: center;  /* Vertically align the label and select */
}

label[for="include_source"] {
    margin-right: 10px;  /* Add some space between the label and the select */
}
label[for="include_source"] {
    display: inline-block;
    align: left;
    margin-right: 10px;  /* Added margin to separate the elements */
}
#include_source {
    display: inline-block;
    margin-right: 10px;
}

#input_field_name {
  margin-top: 13px !important;
}

#textarea_description {
  margin-top: 5px !important;
}




#textarea_description, #textarea_prompt {
  height: 4em; /* Set the height equivalent to 3 lines */
  resize: none; /* Makes the textareas non-resizable */
  overflow-y: auto; /* Allows vertical scrolling if the content exceeds the visible area */
}

#config_edit_select_client {
  width: 120px;
    margin: 10px;
    margin-left: 10px;
}


#button_config_edit_select_client,
#button_config_edit_select_client_2,






.config_edit_select_scope {
  width: 150px;
  margin-left: 20px;
  margin-right: -363px;
}

#checkbox_label {
  width: 150px;

}




