#sign_in_header {
  margin-top: -50px;
  margin-bottom: 20px;
  background-color: #001848;
  height: 65px;
  position: relative;
}

#img_logo_top_left {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 40px; /* 75% of the header height (75px) */
  bottom: 10px;
  width: auto;
  max-height: 100%;
}
