.container_input_page {
  display: flex;
  height: 100vh;
}

.left-div_input_page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 300px;
  overflow: hidden;
  border: none;
}

.inner-div-top_input_page {
  flex: 1;
  overflow-y: hidden;
  border: none;
}

#form-element_input_page_report_client, #form-element_input_page_report_prospect {
  width: 80%;
  margin: 10px;
  margin-top: 5px;
  margin-left: 5px;
}



.page_reports_p {
  color: white;
  margin-left: -100px;

  font-size: 14px !important;
}
.ant-picker.form-element_input_page {
    margin: 0px; /* or any specific value you prefer */
}
.ant-select {
    margin-bottom: 5px;
    margin-top: 5px;
}
#page_reports_end_p {
  color: white;
  margin-left: -100px;
  margin-top: 20px;
  font-size: 14px;
}
.ant-select-selector {
    margin-bottom: 10px;
    margin-top: 10px;
}


.custom-textarea {
  width: calc(100% - 20px);
  height: 65%;
  box-sizing: border-box;
  resize: none;
  border: none;
  padding: 10px;
}

.download-button {
  margin-right: 10px;
  float: right;
}

#p_report_weekly {
  margin-left: 5px;
  margin-top: 17px;
}

.div_p_report_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;

}


.p_report_title {
  margin: 0; /* to remove the default margin of the <p> element */
  line-height: 20px;
}

#recap_report_textarea {
    height: 600px; /* Increase height to 400px or your desired value */
}

.page_reports_p,
#page_reports_end_p {
    margin-bottom: 5px; /* Adjust this value as needed */
}
#form-element_input_page_report_client.ant-select {
    margin-bottom: 10px
}
