:root {
  --bg-color: #fff;
  --header-color: #d5d51b;
  --text-color: #000;
  --link-color: blue;
  --border-color: black;
  --font-weight: bold;
  --font-style: italic;
}

.menu_class_horizontal {
  position: relative;
  top: -20px;
  margin-bottom: -20px;
}

.menu_class_horizontal .ant-menu-submenu-title,
.menu_class_horizontal .ant-menu-item {
  text-align: left !important;
}

.logout-button {
  position: absolute;
  right: 10px;
}