body {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
  background-color: rgb(0, 111, 96) !important;
  color: var(--text-color);
}

#config_edit_select_client {
  margin-top: 19px !important;
}

.container_input_page {
  display: flex;
  height: 100vh;
}

.scrollable-div {
  width: 300px;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #ccc;
  padding: 10px;
}

.left-div_input_page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 300px;

}

.right-div_input_page {
  width: calc(100% - 220px);
  box-sizing: border-box;
  position: relative;
  padding-top: 20px;
    padding-left: 20px;
  color: white;
}

.right-div_input_page p {
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-textarea {
  width: calc(100% - 20px);
  height: 65%;
  box-sizing: border-box;
  resize: none;
  border: none;
  padding: 10px;
}

#form-element_config_input_page {
  margin-top: -50px !important;
  width: 165px !important;
  margin-left: -5px !important;
}

.container {
  color: white;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden !important;
  max-width: 400px;

  padding: 10px;
}

.column {
  flex: 1;
  padding: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  margin-top: -5px;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  height: 10rem;
}

.checkbox {
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 8px;
}

.scrollable-content {
  width: calc(100% + 17px);
  height: 100%;
  overflow-y: scroll;
  margin-right: -17px;
}

#title_checkboxes {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-left: 20px;
}

#title_universal_fields {
  margin-top: -1px;
}

.right-div_input_page p {
  padding-bottom: 5px;
  margin-left: -925px;
}

#title_specific_fields {
  margin-left: -937px;
  margin-top: 5px;
}

#title_specific_fields, #title_universal_fields {
  position: absolute;
  margin-left: 0;
  margin-top: -25px;
}

#title_universal_fields {
  margin-top: -7px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

#wrapper_checkboxes {
  margin-top: 25px;
  position: relative;
  width: 600px;
  height: 40rem;
  border: 1px solid white;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  text-align: left;
  color: white;
}
#button_save_config {
    margin-top: 20px;
}

#include_source {
  padding-left: 20px;
}