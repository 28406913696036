#crud_table {
  border-collapse: collapse;
  width: 100%;
  margin-top: -20px;
  transform: translateX(-10px);
}

#column_client{
  width:300px;
}

#add_row_crud_table{
  margin-left:-470px !important;
  transform: translateY(-30px) translateX(10px);
  width: 90px;
}

#crud_table th, #crud_table td {
  border: 1px solid white;
  padding: 8px;
  text-align: left;
}

#crud_table th {
  background-color: #f4f4f4;
}

#crud_table td {
  color: white;
}

#crud_table th:nth-child(2),
#crud_table th:nth-child(3),
#crud_table td:nth-child(2),
#crud_table td:nth-child(3) {
  text-align: right;
  width: 10px;
}

#crud_table td:nth-child(2), td:nth-child(1) {
  border-right: solid 1px rgb(0, 111, 96) !important;
}