

.custom-padding {
  margin-top: 10px;
  margin-bottom: 10px;
}

h3 {
  color: white;
}
