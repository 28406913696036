
:root {
  --bg-color: #fff;
  --header-color: #d5d51b;
  --text-color: #000;
  --link-color: blue;
  --border-color: black;
  --font-weight: bold;
  --font-style: italic;
}


body {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
  background-color: rgb(0, 111, 96) !important;
  color: var(--text-color);
}

img {
  max-height: 40%;
  max-width: 40%;
  display: block;
  margin: 0 auto;
}

#h1_for_menu {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

#div_header, #div_header_menu {
  border: 1px solid var(--border-color);

}

textarea {
  resize: none;
}


#textarea_openid {
  height: 80px;
  width: 300px;
}

.span_example_javascripts {
  font-size: 12px;
  font-style: italic;
  margin-left: -20px;
}

#div_header_menu {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  height: 35px;
  position: relative;
  margin-top: 10px;
}

#menu_entry_home {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.menu_entry {
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}

.bold {
  font-weight: bold;
}

.a_navigation, .a_logout {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  color: var(--link-color);
}

.a_logout {
  font-style: italic;
  display: inline-block;
  margin-right: 30px;
  font-size: 12px;
}

.p_genai_quotes {
  font-weight: bold;
  text-align: left;
}

.span_user {
  font-size: 12px;
}

.span_logout {
  margin: 10px 0 !important;
  background-color: rgb(0, 37, 90) !important;
  font-weight: bold !important;
}

.p_quote {
  font-style: italic;
}

.logo_in_header {
  border-color: #333;
  border-style: solid;
  border-width: 3px;
  border-radius: 10%;
  margin-top: 30px;
}


#button_homepage {
  font-size: 12px !important;
}


#l_login_google {
  border: 1px solid;
}

#gen_ai_link {
  margin-right: 30px;
}

#home_link {
  margin-right: 12px;
}

#reports_link {
  margin-left: -10px;
}

#boldarator {
  font-weight: bold !important;
  color: cadetblue;
}

.span_footer {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}

.logo_create {
  height: 40%;
  width: 40%;
}

.logo_gen_ai {
  height: 25%;
  width: 25%;
}

.logo_notes {
  height: 25%;
  width: 25%;
  align-self: center;
}

.header {
  position: absolute;
  top: 85px;
  right: -5px;
}

.menu_class_horizontal {
  position: relative;
  top: -20px;
  margin-bottom: -20px;
}

#logout_button {
  color: black;
  font-style: normal;
  font-size: 12px;
  width: 200px;
  padding: 0;
  margin-right: 20px;
}

.a_navigation {
  display: inline;
}

.div-body-encapsulate {
  position: relative;
  width: 600px;
  padding: 20px;
  display: inline-block;
}

#logout_button {
  position: absolute;
  top: 127px;
  right: 0px;
  border: none !important;
  width: 120px !important;
  margin-right: 20px !important;
}

#nav_logout_right_button {
  margin-left: 15px !important;
}

#logout_button:hover {
  background-color: inherit !important;
  border: none !important;
}

#logout_button:hover .ant-menu-item-only-child {
  color: inherit !important;
}

/* Add styles for the new Sidebar component */
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


.div-content-login {
  background-color: rgb(00, 18, 48) !important;
  display: inline-block;
  width: 350px;
  border-radius: 10px;
  margin-top: 50px;
  padding-top: 80px;
  padding-bottom: 50px;
  padding-right: 10px;
  padding-left: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

#sign_in_header {
  margin-top: -50px;
  margin-bottom: 20px;
  background-color: #001848;
  height: 65px;
  position: relative;
}

#img_logo_top_left {
  position: absolute;
  top: 10px;
  left: 30px;
  height: 40px; /* 75% of the header height (75px) */
  bottom: 10px;
  width: auto;
  max-height: 100%;
}

#header_slogan {
  font-weight: 700;
  left: 50px;
  top: 10px;
  font-size: 18px;
  position: absolute;
  color: white;
}

#google-logo {
  margin-left: 70px !important;
}

#text_inner_button_login_google {
  padding-left: 20px !important;
}

#button_login_google {
  background-color: white;
  width: 220px;
  margin-left: 55px !important;
  z-index: 1;
  font-style: normal;
  text-decoration: none !important;
  color: var(--text-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

#logo_past_sight {
  position: absolute;
  top: -25px;
  left: -100px;
  transform: scale(0.7);
}


#icon-sign-in-user {
  height: 50px;
  margin-top: -50px;
}

#sign-in-user {
  margin-left: 110px;
}

#label_welcome_back {
  font-family: "ColfaxAI", -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: bolder;
  margin-left: 10px;
  color: white;
}


/* Clear float after the image and div */
#button_login_google:after {
  content: "";
  display: table;
  clear: both;
}


#button_login_google:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

#google-logo {
  margin-left: 80px !important;
  height: 20px;
  width: 20px;
  margin-bottom: -35px;
  display: flex;
  align-items: center;
}

.logout-message {
  text-align: center;
  margin-top: 100px;
}

.logout-message h1 {
  font-size: 24px;
}

.logout-message p {
  font-size: 18px;
  margin: 10px 0;
}

.logout-message a {
  font-size: 18px;
  color: var(--link-color);
  text-decoration: none;
}


 h2 {
    color: white;
}

#text_inner_button_login_google {
  padding-left: 20px !important;
}

#button_login_google {
  background-color: white;
  width: 220px;
  margin-left: 55px !important;
  z-index: 1;
  font-style: normal;
  text-decoration: none !important;
  color: var(--text-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

#google-logo {
  margin-left: 70px !important;
}

#text_inner_button_login_google {
  padding-left: 20px !important;
}

#sign_in_header {
  margin-top: -50px;
  margin-bottom: 20px;
  background-color: #001848;
  height: 65px;
  position: relative;
}

.div-content-login {
  background-color: rgb(00, 18, 48) !important;
  display: inline-block;
  width: 350px;
  border-radius: 10px;
  margin-top: 50px;
  padding-top: 80px;
  padding-bottom: 50px;
  padding-right: 10px;
  padding-left: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

#icon-sign-in-user {
  height: 50px;
  margin-top: -50px;
}

#sign-in-user {
  margin-left: 110px;
}

#label_welcome_back {
  font-family: "ColfaxAI", -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: bolder;
  margin-left: 10px;
  color: white;
}
#button_login_google:after {
  content: "";
  display: table;
  clear: both;
}


#button_login_google:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

#google-logo {
  margin-left: 80px !important;
  height: 20px;
  width: 20px;
  margin-bottom: -35px;
  display: flex;
  align-items: center;
}

.default_input_box {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-style: italic;
  color: #999;
  margin: 5px 0;
}